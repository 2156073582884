import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { GeistProvider, CssBaseline } from "@geist-ui/react";
import { Card, Dot, Loading, Page, Tabs, Spacer } from "@geist-ui/react";
// import { Circle } from "@geist-ui/react-icons";

const queryClient = new QueryClient();

function App() {
  return (
    <GeistProvider>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <div>
          <Page size="small">
            <Page.Header>
              <h2>Most fabulous blogs</h2>
            </Page.Header>
            <Page.Content>
              <Tabs initialValue="1">
                <Tabs.Item label="Home" value="1">
                  <h3>Welcome to this awesome data site</h3>
                </Tabs.Item>
                <Tabs.Item label="Data" value="2">
                  <Example />
                </Tabs.Item>
                <Tabs.Item label="About" value="3">
                  <h3>About</h3>
                  <p>Digga, voll die krasse Firma und alles!</p>
                </Tabs.Item>
              </Tabs>
            </Page.Content>
            <Page.Footer>
              <h4>Footer</h4>
            </Page.Footer>
          </Page>
        </div>
      </QueryClientProvider>
    </GeistProvider>
  );
}

function Example() {
  const { isLoading, error, data, isFetching } = useQuery("blogData", () =>
    fetch("https://mongoapi-igdfwm6cna-ew.a.run.app/").then((res) => res.json())
  );

  if (isLoading) return <Loading />;

  if (error) return "An error has occurred: " + error.message;

  return (
    <div>
      {data.blogs.map((blog) => (
        <>
          <Card shadow key={blog._id}>
            <Dot type="success" />
            {blog.title}: {blog.description}
          </Card>
          <Spacer y={0.5} />
        </>
      ))}

      <div>{isFetching ? "Updating..." : ""}</div>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </div>
  );
}

export default App;
